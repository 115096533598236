<template>

  <div :key="itemData.deal_type_id + 1">
    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            class="align-text-top"
            icon="HomeIcon"
            size="15"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'agreements' }">
          {{ $t('label_agreements') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ $t('label_addconvert_to_agreement') }}
        </b-breadcrumb-item>
      </div>
    </div>

    <b-col
      cols="12"
      md="12"
    >
      <b-card
        no-body
        class="mb-0"
      >

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{
              action == 'editing' ? $t('label_addconvert_to_agreement') :
              $t('label_addconvert_to_agreement')
            }}
          </h5>

        </div>


        <validation-observer
          #default="{ handleSubmit, invalid, errors, flags }"
          :ref="MODULE_PREFIX + '_FORM'"
          tag="form"
          class="p-2"
        >
          <div v-show="!isSolicitor">
            <validation-provider
              #default="validationProps"
              :name="$t('label_deal_type')"
              :rules="!isSolicitor ? 'required' : ''"
            >
              <b-form-group
                :label="$t('label_deal_type')"
                class="required"
                :state="getValidationState(validationProps)"
              >
                <v-select
                  v-model="itemData.deal_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="deal_types"
                  :reduce="val => val.id"
                  label="name"
                  :clearable="false"
                  :disabled="loading || isSolicitor"
                  :placeholder="$t('label_select')"
                >
                  <template v-slot:option="option">
                    <span class="">{{ option.name }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <!--<span :class="option.icon"></span>-->
                    <span class="">{{ option.name }}</span>
                  </template>

                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row class="flex-wrap agrm-add-client-toolbar align-items-end ">

              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationProps"
                  :name="$t('label_chose_related_client')"
                  rules=""
                  slim
                >
                  <b-form-group
                    class="full-width-sm"
                    :label="$t('label_chose_related_client')"

                    :state="getValidationState(validationProps)"
                  >

                    <infinity-scroll
                      v-model="itemData.contact_id"
                      :multiple="false"
                      class="mr-1 "
                      style="min-width: 220px"
                      selected-prop="id"
                      url="/contact"
                      :placeholder="$t('label_select')"
                      :default-selection="chosenContact"
                      @input="contactChanged($event)"
                      :disabled="loading || isSolicitor"
                    >
                      <template #label="{item}">
                      <span v-if="item.first_name || item.last_name ">
                        {{ item.first_name + ' ' + item.last_name }} {{ item.firm_name ? `(${item.firm_name})` : '' }}
                      </span>
                        <span v-else-if="item.firm_name">
                        <feather-icon icon="LayersIcon" />
                        {{ item.firm_name }}
                      </span>
                        <span v-else>-</span>
                      </template>

                    </infinity-scroll>
                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                      {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="9"
              >
                <b-button
                  :disabled="loading || isSolicitor"
                  type="button"
                  class="mr-1 mb-1"
                  variant="primary"
                  @click.prevent="isContactSidebarOpen = true"
                >{{ $t('label_add_client') }}
                </b-button>
                <b-button
                  :disabled="loading || isSolicitor"
                  v-if="allowAssignContactData"
                  class="mb-1"
                  variant="success"
                  @click="assignContactData()"
                >
                  {{ $t('label_copy_contact_data_to_agreement') }}
                </b-button>
              </b-col>

            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationProps"
                  :name="$t('label_agreement_date')"
                >
                  <b-form-group
                    :label="$t('label_agreement_date')"
                    :state="getValidationState(validationProps)"
                  >

                    <custom-date-picker
                      :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="itemData.agreement_at"

                      :disabled="loading || isSolicitor"
                      @input="itemData.agreement_at = $event"
                    >
                      <template #clear-btn="{ vm }">
                        <feather-icon
                          icon="XIcon"
                          size="14"
                        />
                      </template>
                      <div slot="label">
                        <feather-icon
                          title="Clear"
                          data-toggle
                          icon="CalendarIcon"
                          size="18"
                        />
                      </div>
                    </custom-date-picker>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                      {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationProps"
                  :name="$t('label_limitation_date')"
                  rules=""
                >
                  <b-form-group
                    :label="$t('label_limitation_date')"
                    label-for="limitation_date"
                    :state="getValidationState(validationProps)"
                  >

                    <custom-date-picker
                      :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="itemData.limitation_at"

                      :disabled="loading || isSolicitor"
                      @input="itemData.limitation_at = $event"
                    >
                      <template #clear-btn="{ vm }">
                        <feather-icon
                          icon="XIcon"
                          size="14"
                        />
                      </template>
                      <div slot="label">
                        <feather-icon
                          title="Clear"
                          data-toggle
                          icon="CalendarIcon"
                          size="18"
                        />
                      </div>
                    </custom-date-picker>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                      {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationProps"
                  :name="$t('label_contract_termination_date')"
                  rules=""
                >
                  <b-form-group
                    :label="$t('label_contract_termination_date')"
                    class=""
                    :state="getValidationState(validationProps)"
                  >

                    <custom-date-picker
                      :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="itemData.termination_at"

                      :disabled="loading || isSolicitor"
                      @input="itemData.termination_at = $event"
                    >
                      <template #clear-btn="{ vm }">
                        <feather-icon
                          icon="XIcon"
                          size="14"
                        />
                      </template>
                      <div slot="label">
                        <feather-icon
                          title="Clear"
                          data-toggle
                          icon="CalendarIcon"
                          size="18"
                        />
                      </div>
                    </custom-date-picker>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                      {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationProps"
                  :name="$t('label_agreement_expiration_date')"
                >
                  <b-form-group
                    :label="$t('label_agreement_expiration_date')"
                    :state="getValidationState(validationProps)"
                  >

                    <custom-date-picker
                      :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="itemData.deadline_at"

                      :disabled="loading || isSolicitor"
                      @input="itemData.deadline_at = $event"
                    >
                      <template #clear-btn="{ vm }">
                        <feather-icon
                          icon="XIcon"
                          size="14"
                        />
                      </template>
                      <div slot="label">
                        <feather-icon
                          title="Clear"
                          data-toggle
                          icon="CalendarIcon"
                          size="18"
                        />
                      </div>
                    </custom-date-picker>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                      {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-if="dealType_alias == 'traffic_accident'"
              :key="dealType_alias"
            >
              <b-col sm="12">
                <validation-provider
                  #default="validationContext"
                  :name="$t('label_value_of_claims')"
                  slim
                  tag="div"
                >
                  <b-form-group :label="$t('label_value_of_claims')">
                    <b-form-input
                      :disabled="loading || isSolicitor"
                      v-model="itemData.value_of_claims"
                      class="price-input"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  :name="$t('label_description_of_claims')"
                  slim
                  tag="div"
                >
                  <b-form-group :label="$t('label_description_of_claims')">
                    <b-form-textarea
                      :disabled="loading || isSolicitor"
                      id="n-description_of_claims"
                      v-model="itemData.description_of_claims"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <h5 class="mt-2 mb-2">
              {{ $t('label_customer_data') }}
            </h5>

            <validation-provider
              #default="validationProps"
              :name="$t('label_choose_the_type_of_customer')"
              :rules="!isSolicitor ? 'required' : ''"
            >
              <b-form-group
                :label="$t('label_choose_the_type_of_customer')"
                class="required"
                :state="getValidationState(validationProps)"
              >
                <!--                      @input="isFirm = $event && customer_types.findWhere('id', $event).name_alias == 'firma'"-->
                <v-select
                  :disabled="loading || isSolicitor"
                  v-model="itemData.customer_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customer_types"
                  :reduce="val => val.id"
                  label="name"
                  :clearable="false"
                  :placeholder="$t('label_select')"
                  input-id="customer_type_id"
                >
                  <template v-slot:option="option">
                    <span class="">{{ option.name }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <!--<span :class="option.icon"></span>-->
                    <span class="">{{ option.name }}</span>
                  </template>

                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="isFirm"

              #default="validationContext"
              :name="$t('label_company_name')"
              :rules="!isSolicitor ? 'required' : ''"
              slim
              tag="div"
            >
              <b-form-group
                :label="$t('label_company_name')"
                class="required"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"

                  v-model="itemData.firm_name"
                  :placeholder="$t('label_company_name')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="isFirm"
              #default="validationProps"
              :name="$t('label_nip')"
              rules=""
            >
              <b-form-group
                :label="$t('label_nip')"
                label-for="nip"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  id="nip"
                  v-model="itemData.nip"
                  :placeholder="$t('label_nip')"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationProps"
              :name="$t('label_first_name')"
              :rules="isFirm ? '' : (!isSolicitor ? 'required' : '')"
            >
              <b-form-group
                :label="$t('label_first_name')"
                :class="isFirm ? '' : 'required'"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.first_name"
                  :state="getValidationState(validationProps)"
                  :placeholder="$t('label_first_name')"
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Username -->
            <validation-provider
              #default="validationProps"
              :name="$t('label_last_name')"
              :rules="isFirm ? '' : (!isSolicitor ? 'required' : '')"
            >
              <b-form-group
                :label="$t('label_last_name')"
                :class="isFirm ? '' : 'required'"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.last_name"
                  :placeholder="$t('label_last_name')"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="!isFirm"
              #default="validationProps"
              :name="$t('label_pesel_number')"
              rules=""
            >
              <b-form-group
                :label="$t('label_pesel_number')"
                label-for="pesel"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  id="pesel"
                  v-model="itemData.pesel"
                  :placeholder="$t('label_pesel_number')"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('label_phone')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_phone')"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.phone"
                  :placeholder="$t('label_phone')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('label_private_phone')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_private_phone')"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.phone_private"
                  :placeholder="$t('label_private_phone')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('label_email_address')"
              :rules="!isSolicitor ? 'required|email' : ''"
              slim
            >
              <b-form-group
                :label="$t('label_email_address')"
                class="required"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.email"
                  :placeholder="$t('label_email_address')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('label_private_email')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_private_email')"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.email_private"
                  :placeholder="$t('label_private_email')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('label_client_bank_account_number')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_client_bank_account_number')"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.bank_account_number"
                  :placeholder="$t('label_client_bank_account_number')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('label_swift_number')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_swift_number')"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.swift_number"
                  :placeholder="$t('label_swift_number')"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider

              #default="validationProps"
              :name="$t('label_address_from_agreement')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_address_from_agreement')"
                label-for="n-address_agreement"
              >
                <b-form-textarea
                  :disabled="loading || isSolicitor"
                  id="n-address_agreement"
                  v-model="itemData.address_agreement"
                  :state="getValidationState(validationProps)"
                  :placeholder="$t('label_address_from_agreement')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider

              #default="validationProps"
              :name="$t('label_correspondence_address')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_correspondence_address')"
                label-for="n-correspondence_address"
              >
                <b-form-textarea
                  :disabled="loading || isSolicitor"
                  id="n-address_correspondence"
                  v-model="itemData.address_correspondence"
                  :state="getValidationState(validationProps)"
                  :placeholder="$t('label_correspondence_address')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div
              v-if="dealType_alias == 'traffic_accident'"
              :key="dealType_alias + 2"
            >
              <h5 class="mt-2 mb-2">
                {{ $t('label_incident_information') }}
              </h5>

              <validation-provider
                #default="validationProps"
                :name="$t('label_date_of_event')"
                :rules="!isSolicitor ? 'required' : ''"
              >
                <b-form-group
                  :label="$t('label_date_of_event')"
                  class="required"
                  :state="getValidationState(validationProps)"
                >
                  <custom-date-picker
                    :disabled="loading || isSolicitor"
                    format="YYYY-MM-DD"
                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                    :locale="$store.state.appConfig.locale"
                    :placeholder="$t('label_DD-MM-YYYY')"
                    :value="itemData.traffic_accident_date"

                    @input="itemData.traffic_accident_date = $event"
                  >
                    <template #clear-btn="{ vm }">
                      <feather-icon
                        icon="XIcon"
                        size="14"
                      />
                    </template>
                    <div slot="label">
                      <feather-icon
                        title="Clear"
                        data-toggle
                        icon="CalendarIcon"
                        size="18"
                      />
                    </div>
                  </custom-date-picker>

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationProps"
                :name="$t('label_accident_submission_date')"
                :rules="!isSolicitor ? 'required' : ''"
              >
                <b-form-group
                  :label="$t('label_accident_submission_date')"
                  class="required"
                  :state="getValidationState(validationProps)"
                >

                  <custom-date-picker
                    :disabled="loading || isSolicitor"
                    format="YYYY-MM-DD"
                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                    :locale="$store.state.appConfig.locale"
                    :placeholder="$t('label_DD-MM-YYYY')"
                    :value="itemData.traffic_accident_submission_date"

                    @input="itemData.traffic_accident_submission_date = $event"
                  >
                    <template #clear-btn="{ vm }">
                      <feather-icon
                        icon="XIcon"
                        size="14"
                      />
                    </template>
                    <div slot="label">
                      <feather-icon
                        title="Clear"
                        data-toggle
                        icon="CalendarIcon"
                        size="18"
                      />
                    </div>
                  </custom-date-picker>

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationProps"
                :name="$t('label_accident_country')"
                rules=""
              >
                <b-form-group
                  :label="$t('label_accident_country')"
                  class=""
                  :state="getValidationState(validationProps)"
                >

                  <infinity-scroll
                    :disabled="loading || isSolicitor"
                    v-model="itemData.traffic_accident_country_id"
                    selected-prop="idCountry"
                    :query-params="{traffic_accident:1}"
                    url="/countries"
                    :multiple="false"
                    :placeholder="$t('label_select')"
                    :default-selection="chosenAccidentCountry"
                  >
                    <template #label="{item}">
                      {{ item.countryName }}
                    </template>

                  </infinity-scroll>

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider

                #default="validationProps"
                :name="$t('label_accident_address')"
                slim
              >
                <b-form-group
                  :label="$t('label_accident_address')"
                  label-for="traffic_accident_address"
                >
                  <b-form-textarea
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_address"
                    v-model="itemData.traffic_accident_address"
                    :placeholder="$t('label_accident_address')"
                    :state="getValidationState(validationProps)"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-form-checkbox
                :disabled="loading || isSolicitor"
                v-model="itemData.traffic_accident_is_police_present"
                switch
                value="1"
                class="mt-1 mb-1"
                unchecked-value="0"
              >
                <label>{{ $t('label_is_police_present') }}</label>
              </b-form-checkbox>

              <validation-provider
                v-if="itemData.traffic_accident_is_police_present == 1"

                #default="validationProps"
                :name="$t('label_police_present_on_accident')"
                rules=""
                slim
              >
                <b-form-group
                  :label="$t('label_police_present_on_accident')"
                  label-for="traffic_accident_police_description"
                  class=""
                >
                  <b-form-textarea
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_police_description"
                    v-model="itemData.traffic_accident_police_description"
                    :placeholder="$t('label_police_present_on_accident')"
                    :state="getValidationState(validationProps)"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider

                #default="validationProps"
                :name="$t('label_police_present_on_accident')"
                rules=""
                slim
              >
                <b-form-group
                  :label="$t('label_accident_other_damages')"
                  label-for="traffic_accident_other_damages"
                  class=""
                >
                  <b-form-textarea
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_other_damages"
                    v-model="itemData.traffic_accident_other_damages"
                    :placeholder="$t('label_accident_other_damages')"
                    :state="getValidationState(validationProps)"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <h5 class="mt-2 mb-2">
                {{ $t('label_victim_vehicle_info') }}
              </h5>

              <validation-provider
                #default="validationProps"
                :name="$t('label_victim_vehicle_brand')"
              >
                <b-form-group
                  :label="$t('label_victim_vehicle_brand')"
                  label-for="label_victim_vehicle_brand"
                >
                  <b-form-input
                    :disabled="loading || isSolicitor"
                    id="damaged_vehicle_brand"
                    v-model="itemData.traffic_accident_victim_vehicle_brand"
                    type="text"
                    :placeholder="$t('label_victim_vehicle_brand')"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationProps"
                :name="1+$t('label_victim_vehicle_registration_number')"
                :rules="!isSolicitor ? 'alpha-num' : ''"
              >
                <b-form-group
                  :label="$t('label_victim_vehicle_registration_number')"
                  label-for="label_victim_vehicle_registration_number"
                >
                  <b-form-input
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_victim_protocol_number"
                    v-model="itemData.traffic_accident_victim_registration_number"
                    type="text"
                    :placeholder="$t('label_victim_vehicle_registration_number')"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider

                #default="validationProps"
                :name="$t('label_police_present_on_accident')"
                rules=""
                slim
              >
                <b-form-group
                  :label="$t('label_victim_driver_info')"
                  label-for="traffic_accident_victim_driver_info"
                  class=""
                >
                  <b-form-textarea
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_victim_driver_info"
                    v-model="itemData.traffic_accident_victim_driver_info"
                    :placeholder="$t('label_victim_driver_info')"
                    :state="getValidationState(validationProps)"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationProps"
                :name="$t('label_accident_protocol_number')"
              >
                <b-form-group
                  :label="$t('label_accident_protocol_number')"
                  label-for="traffic_accident_victim_protocol_number"
                >
                  <b-form-input
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_victim_protocol_number"
                    v-model="itemData.traffic_accident_victim_protocol_number"
                    type="text"
                    :placeholder="$t('label_accident_protocol_number')"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationProps"
                :name="$t('label_cancellary_number')"
                rules=""
              >
                <b-form-group
                  :label="$t('label_cancellary_number')"
                  label-for="traffic_accident_victim_cancellary_number"
                >
                  <b-form-input
                    id="traffic_accident_victim_cancellary_number"
                    v-model="itemData.traffic_accident_victim_cancellary_number"
                    type="text"
                    :placeholder="$t('label_cancellary_number')"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <h5 class="mt-2 mb-2">
                {{ $t('label_dealer_data') }}
              </h5>

              <validation-provider
                #default="validationProps"
                :name="$t('label_vehicle_brand')"
              >
                <b-form-group
                  :label="$t('label_vehicle_brand')"
                  label-for="traffic_accident_damager_vehicle_brand"
                >
                  <b-form-input
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_damager_vehicle_brand"
                    v-model="itemData.traffic_accident_damager_vehicle_brand"
                    type="text"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationProps"
                :name="$t('label_vehicle_registration_number')"
                :rules="!isSolicitor ? 'alpha-num' : ''"
              >
                <b-form-group
                  :label="$t('label_vehicle_registration_number')"
                  label-for="traffic_accident_damager_vehicle_registration_number"
                >
                  <b-form-input
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_damager_vehicle_registration_number"
                    v-model="itemData.traffic_accident_damager_vehicle_registration_number"
                    type="text"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationProps"
                :name="$t('label_damager_insurance_company_name')"
                rules=""
              >
                <b-form-group
                  :label="$t('label_damager_insurance_company_name')"
                  label-for="traffic_accident_damager_insurance_company_name"
                >
                  <b-form-input
                    id="traffic_accident_damager_insurance_company_name"
                    v-model="itemData.traffic_accident_damager_insurance_company_name"
                    type="text"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationProps"
                :name="$t('label_damager_insurance_company_phone')"
                rules=""
              >
                <b-form-group
                  :label="$t('label_damager_insurance_company_phone')"
                  label-for="traffic_accident_damager_insurance_company_phone"
                >
                  <b-form-input
                    id="traffic_accident_damager_insurance_company_phone"
                    v-model="itemData.traffic_accident_damager_insurance_company_phone"
                    type="text"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationProps"
                :name="$t('label_damager_insurance_company_deal_number')"
              >
                <b-form-group
                  :label="$t('label_damager_insurance_company_deal_number')"
                  label-for="traffic_accident_damager_insurance_company_deal_number"
                >
                  <b-form-input
                    id="traffic_accident_damager_insurance_company_deal_number"
                    v-model="itemData.traffic_accident_damager_insurance_company_deal_number"
                    type="text"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider

                #default="validationProps"
                :name="$t('label_claim_amount')"
                rules=""
                slim
              >
                <b-form-group
                  :label="$t('label_claim_amount')"
                  label-for="traffic_accident_claim_amount"
                  class=""
                >
                  <b-form-textarea
                    :disabled="loading || isSolicitor"
                    id="traffic_accident_claim_amount"
                    v-model="itemData.traffic_accident_claim_amount"
                    :placeholder="$t('label_claim_amount')"
                    :state="getValidationState(validationProps)"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>


            <b-form-checkbox
              v-model="itemData.notify_about_deadline"
              switch
              value="1"
              class="mt-1 mb-1"
              unchecked-value="0"
            >
              <label>{{ $t('label_notify_about_deadline') }}</label>
            </b-form-checkbox>

            <template v-if="itemData.notify_about_deadline == 1">
              <validation-provider
                #default="validationProps"
                :name="$t('label_notify_users')"
                :rules="!isSolicitor ? 'required' : ''"
              >
                <b-form-group
                  :label="$t('label_notify_users')"
                  class=""
                  :state="getValidationState(validationProps)"
                >

                  <infinity-scroll
                    :disabled="loading || isSolicitor"
                    v-model="itemData.notify_users_ids"
                    selected-prop="id"
                    url="/user"
                    :multiple="true"
                    :placeholder="$t('label_select')"
                    :default-selection="chosenNotifyUsers"
                  >
                    <template #label="{item}">
                      {{ item.name }}
                    </template>

                  </infinity-scroll>

                  <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group
                v-if="itemData.email"
                :label="$t('label_notify_contact')"
                class=""
              >
                <b-form-checkbox
                  :disabled="loading || isSolicitor"
                  v-model="itemData.notify_contact"
                  value="1"
                  class=""
                  unchecked-value="0"
                >
                  <label>{{ $t('label_notify_contact') }} <span>{{ "(" + itemData.email + ")" }}</span></label>
                </b-form-checkbox>
              </b-form-group>
              <validation-provider
                #default="validationProps"
                :name="$t('label_notify_before_days')"
                :rules="!isSolicitor ? 'required|numeric|min:0' : ''"
              >
                <b-form-group
                  :label="$t('label_notify_before_days')"
                  label-for="notify_before_days"
                >
                  <b-form-input
                    :disabled="loading || isSolicitor"
                    id="notify_before_days"
                    v-model="itemData.notify_before_days"
                    type="number"
                    :state="getValidationState(validationProps)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationProps.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <br>
              <br>
            </template>


            <validation-provider
              #default="validationProps"
              :name="$t('label_deal_number')"
            >
              <b-form-group
                :label="$t('label_deal_number')"
              >
                <b-form-input
                  :disabled="loading || isSolicitor"
                  v-model="itemData.deal_number"
                  :state="getValidationState(validationProps)"
                  :placeholder="$t('label_deal_number')"
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <!--*************************PROCURATORS BLOCK*********************************************************-->
            <dynamic-items-block
              class="mt-2"
              alias="procurators"
              label="label_procurator"
              :show-items-section.sync="showProcuratorsSection"
              :blank-item="{name: '', func: '', phone: '', email: ''}"
              :items.sync="savedProcurators"
              :new-items.sync="new_procurators"
              @deleteItemServer="deleteProcurator($event)"
            >
              <template #form="{item, ind, deleteItem}">
                <button
                  :style="{top: ind>0? '14px' : '-17px'}"
                  class="remove-procurator-item btn  "
                  type="button"
                  @click="deleteItem()"
                >
                  <feather-icon
                    color="red"
                    title="Clear"
                    data-toggle
                    icon="XIcon"
                    size="25"
                  />
                </button>
                <hr
                  v-if="ind > 0"
                  style="width:100%"
                >
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >

                  <validation-provider
                    #default="validationProps"
                    :key="ind"
                    :name="$t('label_name_and_surname')"
                    slim
                    :rules="!isSolicitor ? 'required' : ''"
                    :vid="'proc_name_and_surname_' + (item.id? '__' + item.id : ind)"
                  >
                    <b-form-group
                      :label="$t('label_name_and_surname')"
                      class="required"
                    >
                      <b-form-input
                        :disabled="loading || isSolicitor"
                        v-model="item.name"
                        :state="getValidationState(validationProps)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationProps.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >

                  <validation-provider
                    #default="validationProps"
                    :name="$t('label_function')"
                    slim
                    :rules="!isSolicitor ? 'required' : ''"
                    :vid="'proc_function_' + (item.id? '__' + item.id : ind)"
                  >
                    <b-form-group
                      :label="$t('label_function')"
                      class="required"
                    >

                      <b-form-input
                        :disabled="loading || isSolicitor"
                        v-model="item.func"
                        :state="getValidationState(validationProps)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationProps.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >

                  <validation-provider
                    #default="validationProps"
                    :name="$t('label_email')"
                    slim
                    :rules="!isSolicitor ? 'required|email' : ''"
                    :vid="'proc_email_' + (item.id? '__' + item.id : ind)"
                  >
                    <b-form-group
                      :disabled="loading || isSolicitor"
                      :label="$t('label_email')"
                      class="required"
                    >
                      <b-form-input

                        v-model="item.email"
                        :state="getValidationState(validationProps)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationProps.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >

                  <validation-provider
                    #default="validationProps"
                    :name="$t('label_phone')"
                    slim
                    :rules="!isSolicitor ? 'required' : ''"
                    :vid="'proc_phone_' + (item.id? '__' + item.id : ind)"
                  >
                    <b-form-group
                      :label="$t('label_phone')"
                      class="required"
                    >

                      <b-form-input
                        :disabled="loading || isSolicitor"
                        v-model="item.phone"
                        :state="getValidationState(validationProps)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationProps.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>

            </dynamic-items-block>
            <!--*************************PROCURATORS BLOCK*********************************************************-->

            <validation-provider
              #default="validationProps"
              :name="$t('label_type_of_ageement')"
              :rules="!isSolicitor ? 'required' : ''"
            >
              <b-form-group
                :label="$t('label_type_of_ageement')"
                class="required"
                :state="getValidationState(validationProps)"
              >
                <v-select

                  v-model="itemData.agreement_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="agreement_types"
                  :reduce="val => val.id"
                  label="name"
                  :disabled="loading || isSolicitor"
                  :clearable="false"
                  :placeholder="$t('label_select')"
                >
                  <template v-slot:option="option">
                    <span class="">{{ option.name }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <!--<span :class="option.icon"></span>-->
                    <span class="">{{ option.name }}</span>
                  </template>

                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationProps"
              :name="$t('label_subject_of_the_order')"
              :rules="!isSolicitor ? 'required' : ''"
            >
              <b-form-group
                :label="$t('label_subject_of_the_order')"
                class="required"
                :state="getValidationState(validationProps)"
              >
                <v-select

                  v-model="itemData.order_subject_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="order_subjects"
                  :reduce="val => val.id"
                  label="name"
                  :disabled="loading || isSolicitor"
                  :clearable="false"
                  :placeholder="$t('label_select')"
                >
                  <template v-slot:option="option">
                    <span class="">{{ option.name }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <!--<span :class="option.icon"></span>-->
                    <span class="">{{ option.name }}</span>
                  </template>

                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="agreementType == 'provision_legal_services' || agreementType == 'legal_service_order_card'"
              #default="validationProps"
              :name="$t('label_agreement_description')"
              rules=""
              slim
            >
              <b-form-group
                :label="$t('label_agreement_description')"
                label-for="agreement_desc"
              >
                <b-form-textarea
                  :disabled="loading || isSolicitor"
                  id="agreement_desc"
                  v-model="itemData.agreement_desc"
                  :state="getValidationState(validationProps)"
                />

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!--**********************************************************************************-->
            <!--v-if="action != 'editing'"-->
            <payment-block
              v-if="!isSolicitor"
              :is-annex="false"
              :item-payment="item_payment"
              :agreement-type="agreementType"
              @paymentDataUpdated="itemData.payment = $event; "
            />
            <!--**************************************************************************************-->


            <validation-provider
              #default="validationProps"
              :name="$t('label_consultant_leading_case')"
              :rules="!isSolicitor ? 'required' : ''"
            >
              <b-form-group
                :label="$t('label_consultant_leading_case')"
                class="required"
                :state="getValidationState(validationProps)"
              >

                <infinity-scroll
                  v-model="itemData.consultant_id"
                  selected-prop="id"
                  url="/consultant"
                  :multiple="false"
                  :disabled="loading || isSolicitor"
                  :placeholder="$t('label_select')"
                  :default-selection="chosenConsultant"
                  @option:selected="(val) => mergeChosenNotifyUsers(val)"
                  @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                >

                  <template #label="{item}">
                    {{ item.name }}
                  </template>

                </infinity-scroll>
                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationProps"
              :name="$t('label_accountants')"
              rules=""
            >
              <b-form-group
                :label="$t('label_accountants')"
                class=""
                :state="getValidationState(validationProps)"
              >

                <infinity-scroll
                  v-model="itemData.accountant_ids"
                  selected-prop="id"
                  url="/accountant"
                  :multiple="true"
                  :disabled="loading || isSolicitor"
                  :placeholder="$t('label_select')"
                  :default-selection="chosenAccountants"
                  @option:selected="(val) => mergeChosenNotifyUsers(val)"
                  @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                >
                  <template #label="{item}">
                    {{ item.name }}
                  </template>

                </infinity-scroll>

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationProps"
              :name="$t('label_recommended')"
              rules=""
            >
              <b-form-group
                :label="$t('label_recommended')"
                class=""
                :state="getValidationState(validationProps)"
              >

                <infinity-scroll
                  v-model="itemData.agent_id"
                  selected-prop="id"
                  url="/agent"
                  :multiple="false"
                  :disabled="loading || isSolicitor"
                  :placeholder="$t('label_select')"
                  :default-selection="chosenAgent"
                  @option:selected="(val) => mergeChosenNotifyUsers(val)"
                  @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                >
                  <template #label="{item}">
                    {{ item.name }}
                  </template>

                </infinity-scroll>

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationProps"
              :name="$t('label_solicitors')"
            >
              <b-form-group
                :label="$t('label_solicitors')"
                :state="getValidationState(validationProps)"
              >

                <infinity-scroll
                  v-model="itemData.solicitor_ids"
                  selected-prop="id"
                  url="/solicitor"
                  :multiple="true"
                  :disabled="loading || isSolicitor"
                  :placeholder="$t('label_select')"
                  :default-selection="chosenSolicitors"
                  @option:selected="(val) => mergeChosenNotifyUsers(val)"
                  @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                >

                  <template #label="{item}">
                    {{ item.name }}
                  </template>

                </infinity-scroll>
                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationProps"
              :name="$t('label_secretaries')"
              rules=""
            >
              <b-form-group
                :label="$t('label_secretaries')"
                class=""
                :state="getValidationState(validationProps)"
              >

                <infinity-scroll
                  v-model="itemData.secretary_ids"
                  selected-prop="id"
                  url="/secretary"
                  :multiple="true"
                  :disabled="loading || isSolicitor"
                  :placeholder="$t('label_select')"
                  :default-selection="chosenSecretaries"
                  @option:selected="(val) => mergeChosenNotifyUsers(val)"
                  @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                >
                  <template #label="{item}">
                    {{ item.name }}
                  </template>

                </infinity-scroll>

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </div>

          <div v-show="isSolicitor">
            <validation-provider
              #default="validationProps"
              :name="$t('label_cancellary_number')"
              rules=""
            >
              <b-form-group
                :label="$t('label_cancellary_number')"
                label-for="traffic_accident_victim_cancellary_number"
              >
                <b-form-input
                  id="traffic_accident_victim_cancellary_number"
                  v-model="itemData.traffic_accident_victim_cancellary_number"
                  type="text"
                  :placeholder="$t('label_cancellary_number')"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationProps"
              :name="$t('label_damager_insurance_company_name')"
              rules=""
            >
              <b-form-group
                :label="$t('label_damager_insurance_company_name')"
                label-for="traffic_accident_damager_insurance_company_name"
              >
                <b-form-input
                  id="traffic_accident_damager_insurance_company_name"
                  v-model="itemData.traffic_accident_damager_insurance_company_name"
                  type="text"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationProps"
              :name="$t('label_damager_insurance_company_phone')"
              rules=""
            >
              <b-form-group
                :label="$t('label_damager_insurance_company_phone')"
                label-for="traffic_accident_damager_insurance_company_phone"
              >
                <b-form-input
                  id="traffic_accident_damager_insurance_company_phone"
                  v-model="itemData.traffic_accident_damager_insurance_company_phone"
                  type="text"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationProps"
              :name="$t('label_damager_insurance_company_deal_number')"
            >
              <b-form-group
                :label="$t('label_damager_insurance_company_deal_number')"
                label-for="traffic_accident_damager_insurance_company_deal_number"
              >
                <b-form-input
                  id="traffic_accident_damager_insurance_company_deal_number"
                  v-model="itemData.traffic_accident_damager_insurance_company_deal_number"
                  type="text"
                  :state="getValidationState(validationProps)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
              :disabled="loading"
            >
              {{ action == 'editing' ? $t('label_submit') : $t('label_submit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >

              <!--@click="hide"-->
              {{ $t('label_cancel') }}

            </b-button>
          </div>

        </validation-observer>

      </b-card>
    </b-col>
    <add-contact
      :edited-item="false"
      :is-sidebar-open.sync="isContactSidebarOpen"
      @item-added="assignContact"
    />
    <!--@refetch-data="refreshDataTable"-->

  </div>

</template>


<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox
} from 'bootstrap-vue'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {alphaNum} from '@validations'
import vSelect from 'vue-select'
import infinityScroll from '@/views/components/infinityScroll'
import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
import addContact from "../contact/modals/itemProcessingModal"
import paymentBlock from './includes/paymentBlock'

import {AGREEMENT_PREFIX, DEAL_PREFIX} from './moduleHelper'
import {CONTACT_PREFIX} from "../contact/moduleHelper"

export default {
  components: {

    BSidebar,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
BCard,
    vSelect,
    addContact,
    BFormCheckbox,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    paymentBlock,
    dynamicItemsBlock,
    infinityScroll,
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      loading: false,
      CONTACT_PREFIX,
      AGREEMENT_PREFIX,
      DEAL_PREFIX,
      MODULE_PREFIX: '',

      is_deal: false,

      create_from_contact_id: false,
      edited_agreement_id: false,
      edited_deal_id: false,
      showProcuratorsSection: false,
      isContactSidebarOpen: false,

      action: 'adding',

      allowAssignContactData: false,
      // showPesel: false,
      // showFirmName: true,
      // isFirm:true,

      itemData: {},

      blankItemData: {
        value_of_claims: 0,
        description_of_claims: '',
        contact_id: null,

        agreement_at: '',
        limitation_at: '',
        deadline_at: '',
        deal_number: '',
        solicitor_ids: [],
        agreement_type_id: '',
        order_subject_id: '',
        agreement_desc: '',
        termination_at: '',
        payment: {},
        notify_about_deadline: 0,
        notify_before_days: 0,
        notify_users_ids: [],
        notify_contact: 0,

        traffic_accident_date: '',
        traffic_accident_submission_date: '',
        traffic_accident_country_id: '',
        traffic_accident_address: '',
        traffic_accident_is_police_present: 0,
        traffic_accident_police_description: '',
        traffic_accident_other_damages: '',
        traffic_accident_victim_vehicle_brand: '',
        traffic_accident_victim_registration_number: '',
        traffic_accident_victim_vehicle_info: '',
        traffic_accident_victim_driver_info: '',
        traffic_accident_victim_protocol_number: '',
        traffic_accident_victim_cancellary_number: '',
        traffic_accident_damager_vehicle_brand: '',
        traffic_accident_damager_vehicle_registration_number: '',
        traffic_accident_damager_insurance_company_name: '',
        traffic_accident_damager_insurance_company_phone: '',
        traffic_accident_damager_insurance_company_deal_number: '',
        traffic_accident_claim_amount: '',
      },

      blankContactData: {
        customer_type_id: null,
        firm_name: '',
        first_name: '',
        last_name: '',
        pesel: '',
        nip: '',
        phone: '',
        phone_private: '',
        email: '',
        email_private: '',
        bank_account_number: '',
        swift_number: '',
        address_agreement: '',
        address_correspondence: '',
        consultant_id: null,
        deal_type_id: null,
        agent_id: null,
        accountant_ids: [],
        secretary_ids: [],
      },
      savedProcurators: [],
      new_procurators: [],

      item_payment: false,

      deal_types: [],
      customer_types: [],
      agreement_types: [],
      order_subjects: [],

      prevRoute: '',

      chosenContact: false,
      chosenAgent: false,
      chosenAccountants: false,
      chosenSecretaries: false,
      chosenConsultant: false,
      chosenSolicitors: [],
      chosenNotifyUsers: [],
      chosenAccidentCountry: [],
      countries: [],
      dealType_alias: '',
      isFirm: false,
      alphaNum,
    }
  },
  computed: {
      isSolicitor() {
          return this.userData?.role === 'solicitor'
      },
    agreementType() {
      if (this.itemData && this.agreement_types && this.agreement_types.length > 0 && this.itemData.agreement_type_id) {
        return this.agreement_types.findWhere('id', this.itemData.agreement_type_id).name_alias;
      }
    }
  },
  watch: {
    'itemData.deal_type_id': function (newVal) {
      if (newVal) {
        let dealType = this.deal_types.findWhere('id', newVal);
        this.dealType_alias = dealType ? dealType.name_alias : '';
      } else {
        this.dealType_alias = '';
      }
    },
    'itemData.customer_type_id': {
      immediate: true,
      handler(newVal, oldVal) {

        if (newVal) {
          let customerType = this.customer_types.findWhere('id', newVal);
          if (customerType) {
            this.isFirm = customerType.name_alias == 'firma';
          } else {
            this.isFirm = false;
          }
        } else {
          this.isFirm = false;
        }

      }
    }
  },

  created() {

    this.is_deal = this.$router.currentRoute.path.includes('/' + this.DEAL_PREFIX + '/');
    this.create_from_contact_id = this.$router.currentRoute.params.contact_id;
    this.edited_agreement_id = this.$router.currentRoute.params.id;
    this.edited_deal_id = this.$router.currentRoute.params.deal_id;

    this.MODULE_PREFIX = this.is_deal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

    if (this.create_from_contact_id) {
      this.getAndSetChosenContact(this.create_from_contact_id, true);
    }

    this.getPageData();

    this.$nextTick(() => {
      this.$nextTick(() => {
        this.initData();
      })
    })
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;

    })
  },
  methods: {
    assignContact(event) {
      this.chosenContact = event
      this.itemData.contact_id = event.id
      this.assignContactData()
    },
    initData() {
      if (this.edited_agreement_id) {
        this.action = 'editing';
        this.async('get', '/' + this.AGREEMENT_PREFIX + '/' + this.edited_agreement_id, {}, function (resp) {
          let editedItem = { ...this.blankItemData, ...this.blankContactData};
          let actualAgreement = resp.data;
          for (let prop in editedItem) {
            if (actualAgreement.hasOwnProperty(prop)) {
              editedItem[prop] = actualAgreement[prop];
            }
          }

          editedItem.id = actualAgreement.id;
          editedItem.order_subject_id = actualAgreement.order_subject_id > 0 ? actualAgreement.order_subject_id : '';

          if (actualAgreement.contact_id) this.getAndSetChosenContact(actualAgreement.contact_id);

          if (actualAgreement.procurators && actualAgreement.procurators.length) {

            let preparedProcurators = [];
            for (let i = 0; i < actualAgreement.procurators.length; i++) {
              let item = actualAgreement.procurators[i];
              preparedProcurators.push({
                name: item.initials,
                func: item.function,
                email: item.email,
                phone: item.phone
              });
            }
            this.savedProcurators = preparedProcurators;

            this.showProcuratorsSection = true;
          }
          this.item_payment = actualAgreement.payments;

          editedItem.payment = {};
          editedItem.agreement_at = formatDate(editedItem.agreement_at, 'DD/MM/YYYY', 'unix');
          editedItem.termination_at = editedItem.termination_at ? formatDate(editedItem.termination_at, 'DD/MM/YYYY', 'unix') : '';
          editedItem.limitation_at = editedItem.limitation_at ? formatDate(editedItem.limitation_at, 'DD/MM/YYYY', 'unix') : '';
          editedItem.deadline_at = editedItem.deadline_at ? formatDate(editedItem.deadline_at, 'DD/MM/YYYY', 'YYYY-MM-DD') : '';
          editedItem.solicitor_ids = actualAgreement.solicitors ? actualAgreement.solicitors.pluck('id') : [];
          editedItem.accountant_ids = actualAgreement.accountants ? actualAgreement.accountants.pluck('id') : [];
          editedItem.secretary_ids = actualAgreement.secretaries ? actualAgreement.secretaries.pluck('id') : [];
          this.itemData = editedItem;

          if (editedItem.agent_id) {
            this.async('get', '/agent/' + editedItem.agent_id, {}, function (resp) {
              this.chosenAgent = resp.data;
            });
          }

          this.async('get', '/consultant/' + editedItem.consultant_id, {}, function (resp) {
            this.chosenConsultant = resp.data;
          });
          this.chosenSolicitors = actualAgreement.solicitors;
          this.chosenNotifyUsers = actualAgreement.notify_users;
          this.chosenAccountants = actualAgreement.accountants;
          this.chosenSecretaries = actualAgreement.secretaries;

          if(editedItem.traffic_accident_country_id && actualAgreement.accidentCountry){
            this.chosenAccidentCountry = actualAgreement.accidentCountry;
          }

          this.$refs[this.MODULE_PREFIX + '_FORM'].validate()
        });

      } else {
        this.action = 'adding';
        this.itemData = { ...this.blankItemData, ...this.blankContactData};
      }
    },
    getAndSetChosenContact(id, allowAssign) {
      this.async('get', '/contact/' + id, {}, (res) => {
        this.chosenContact = res.data;
        if (allowAssign) {
          // this.allowAssignContactData = true;
          this.assignContactData();
        }

      });
    },
    assignContactData(clear) {
      let contactData = { ...this.blankContactData};

      if (!clear && this.chosenContact) {
        for (let prop in this.blankContactData) {
          if (this.chosenContact.hasOwnProperty(prop)) {
            contactData[prop] = this.chosenContact[prop] || this.blankContactData[prop];
          }
        }
      }

      this.itemData = Object.assign(this.itemData, contactData);

      this.chosenAgent = this.chosenContact.agent ? this.chosenContact.agent : null;

      this.chosenConsultant = this.chosenContact.consultant ? this.chosenContact.consultant : null;

      this.chosenSolicitors = this.chosenContact.solicitors ? this.chosenContact.solicitors : [];

      this.chosenNotifyUsers = this.chosenContact.notify_users ? this.chosenContact.notify_users : [];

    },
    onSubmit() {
      this.loading = true
      try {
        for (let payType in this.itemData.payment) {
          if (typeof this.itemData.payment[payType] == 'object') {
            for (let payProp in this.itemData.payment[payType]) {
              if (payProp == 'net_payment' || payProp == 'gross_payment') {
                this.itemData.payment[payType][payProp] = this.itemData.payment[payType][payProp].formatPriceToNumber();
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      }

      try {
        let procurators = this.new_procurators;

        if (procurators && procurators.length > 0) {
          let preparedProcurators = {};
          this.itemData.procurator = 1;
          for (let i = 0; i < procurators.length; i++) {
            let proc = procurators[i];
            for (let prop in proc) {
              if (!preparedProcurators.hasOwnProperty(prop)) {
                preparedProcurators[prop] = [];
              }
              preparedProcurators[prop].push(proc[prop]);
            }
          }

          this.itemData.procurators = preparedProcurators;

        }
      } catch (e) {
      }
      this.itemData.value_of_claims = this.itemData.value_of_claims ? this.itemData.value_of_claims?.formatPriceToNumber() : (0)

      if (this.action == 'adding') {
        this.async('post', '/' + this.MODULE_PREFIX, this.itemData, function (resp) {
              this.$router.push({name: (this.MODULE_PREFIX) + '-details', params: {id: resp.data.item.id}});
              this.loading = false
        });

      } else {
        this.async('put', '/' + this.MODULE_PREFIX + '/' + (this.is_deal ? this.edited_deal_id : this.itemData.id), this.itemData, function (resp) {
          this.$router.push({
            name: this.MODULE_PREFIX + '-details',
            params: {id: this.is_deal ? this.edited_deal_id : this.edited_agreement_id}
          });
          this.loading = false
        });

      }
    },

    getPageData() {

      this.async('get', '/select_options', {params: {options: ['order_subject', 'agreement_types', 'customer_types', 'deal_subjects']}}, function (resp) {
        this.order_subjects = resp.data.order_subject;
        this.deal_types = resp.data.deal_subjects; // todo change on server updating
        this.customer_types = resp.data.customer_types;
        this.agreement_types = resp.data.agreement_types;

        this.blankItemData.customer_type_id = this.itemData.customer_type_id = this.customer_types.findWhere('name_alias', 'firma').id;
        let selectedDealType = this.deal_types.findWhere('is_selected', '1');
        if (selectedDealType) {
          this.itemData.deal_type_id = this.blankItemData.deal_type_id = selectedDealType.id;
          this.itemData.agreement_type_id = this.blankItemData.agreement_type_id = this.agreement_types.findWhere('is_selected', '1').id;
          this.itemData.order_subject_id = this.blankItemData.order_subject_id = this.order_subjects.findWhere('is_selected', '1').id;
        }

      });

      this.async('get', '/countries', {}, (res) => {
        this.countries = res.data
      });
    },
    deleteProcurator(item) {
      this.savedProcurators = this.savedProcurators.filter((o) => o.id != item.id);
    },
    contactChanged(contact_id) {
      if (contact_id) {
        this.allowAssignContactData = true;
        this.itemData.contact_id = contact_id;
        this.getAndSetChosenContact(contact_id);
      } else {
        this.allowAssignContactData = false;
        this.assignContactData(true);
      }
    },

    mergeChosenNotifyUsers(val) {
      // if(val) {
      //   if(val.id != undefined) {
      //       if(!this.itemData.notify_users_ids.includes(val.id)) {
      //           this.itemData.notify_users_ids.push(val.id);
      //           this.chosenNotifyUsers.push(val);
      //       }
      //   } else {
      //         for(let i=0; i<val.length; i++) {
      //             if(!this.itemData.notify_users_ids.includes(val[i].id)) {
      //                 this.itemData.notify_users_ids.push(val[i].id);
      //                 this.chosenNotifyUsers.push(val[i]);
      //             }
      //         }
      //   }
      // }
    },
    unmergeChosenNotifyUsers(val) {

      // if(val.id) {
      //     this.itemData.notify_users_ids.deleteVal(val.id);
      //     this.chosenNotifyUsers.deleteByVal('id',val.id);
      // }
    }
  },


}
</script>
